.wrap-page {
  display: flex;
  flex-direction: column;
  //height: 100vh;
  font-size: 1rem;
  color: #000;
  padding: 5rem 1.5rem .5rem 1.5rem;
  font-weight: 500;
  //height: 100%;
  position: relative;
  .title {
    width: 100%;
    text-align: center;
    .text-title {
      text-transform: uppercase;
      font-weight: 800;
    }
  }
  .wrap-form {
    //flex: 1;
    //min-height: 0;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //overflow: auto;
    .title-form {
      font-size: 24px;
      font-weight: 800;
      text-decoration: underline;
    }
    .block {
      display: flex;
      flex-direction: column;
      border: 1px solid #000;
      margin-bottom: 3rem;
      .thead {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .5rem;
        font-weight: 700;
      }
      .tbody {
        display: flex;
        flex-direction: column;
        .tr {
          display: flex;
        }
        .td {
          flex: 1;
          min-width: 0;
          display: flex;
          padding: .25rem .5rem 16px .25rem;
          border-top: 1px solid #000000;
          border-right: 1px solid #000000;
          position: relative;
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
    .custom-input {
      padding-left: 4px;
      border: 0;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:disabled {
        background-color: white !important;
      }
    }
    .custom-textarea {
      border: 0;
      flex: 1;
      padding: 0 4px;
      &:focus {
        outline: none
      }
      &:disabled {
        background-color: white !important;
      }
    }
    .label-form {
      margin-bottom: 0;
      white-space: nowrap;
    }
    .td.wrap-text-area {
      position: relative;
      .label-form {
        position: absolute;
        top: .25rem;
        left: .5rem;
        line-height: 1.2rem;
      }
      .custom-textarea {
        margin-top: 1.2rem;
        transition: .15s ease-in-out;
        white-space: pre-wrap;
      }
    }
    .group-radio {
      display: flex;
      flex: 1;
      max-width: 50%;
      margin-left: 2.5rem;
      .custom-input {
        align-self: center;
      }
      .label-form {
        margin: 0 4rem 0 1rem;
      }
    }
    .td-wrap-image {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      margin-bottom: 2rem;
      .td-truck {
        width: 400px;
        box-sizing: content-box;
        text-align: center;
        img {
          height: 6rem;
        }
        .image-right {
          transform: rotateY(180deg);
        }
        .image-left {
          transform: rotateY(0deg);
        }
      }
    }
    .control {
      display: flex;
      flex-direction: column;
      width: 100%;
      input {
        max-width: 100%;
      }
    }
    .message-error {
      font-size: 80%;
      color: red;
      margin-top: .25rem;
      position: absolute;
      bottom: 0;
      left: 10px;
    }
  }
  .form {
    //width: 100%;
    //height: 100%
  }
  .btn-container {
    text-align: center;
  }
  .signature {
    height: 200px;
  }
}
.custom-btn-print {
  background-color: #ffffff !important;
  border: 1px solid gold !important;
  border-radius: 50% !important;
  font-size: 1.5rem;
  padding: 1rem !important;
  color: gold !important;
  position: fixed !important;
  bottom: 4rem;
  right: 2rem;
  z-index: 1000;
  &:hover {
    background-color: gold !important;
    color: #242424 !important;
  }
}
.wrap-notify {
  display: none;
  align-items: center;
  .success, .error, .sent-email {
    display: none;
  }
  &.is-success {
    display: flex;
    .success {
      display: block;
      color: green;
    }
  }
  &.is-sent {
    display: flex;
    .sent-email {
      display: block;
      color: green;
    }
  }
  &.is-error {
    display: flex;
    .error {
      display: block;
      color: red;
    }
  }
}
.fake-area {
  white-space: pre-wrap;
  margin-top: 1.2rem;
  color: #737373;
  min-height: 6rem;
}
.text-ellipse {
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 1rem);
  text-overflow: ellipsis;
}
.loading-content {
  position: fixed;
  content: '';
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .1);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media  (max-width: 534px) {
  html {
    font-size: 14px;
  }
  .wrap-page {
    .title {
      .text-title {
        font-size: 2rem;
      }
    }
    .wrap-form {
      .title-form {
        font-size: 1.4rem;
      }
    }
  }
  .wrap-page .wrap-form .td-wrap-image .td-truck {
    &:last-child {
      margin-top: 1rem;
    }
  }
}
