$brand: gold;
main {
  padding-top: 1rem;
}
.wrap-image {
  height: 12rem;
}
.MuiDrawer-root {
  min-height: 100vh;
  border-right:  1px solid rgba(224, 224, 224, 1);
  margin-right: 16px;
  // background: #fff;
}
.RaSidebar-fixed-38, .jss38 {
  position: inherit !important;
  padding-right: 16px;
}
.qlmenu {
  .ql-item-menu {
    padding-top: 15px;
    font-weight: bold;
    font-size: 13px;
    padding-bottom: 15px;
    border-bottom: solid 1px #ccc;
  }

  .MuiTypography-root {
    font-weight: bold;
    font-size: 13px;
  }

  .MuiButtonBase-root {
    border-bottom: solid 1px #ccc;
  }

  .MuiListItemIcon-root {
    color: $brand;
    min-width: 40px;
  }

  .MuiCollapse-container {
    background: #f1eee6;

    .ql-item-menu {
      font-size: 12px;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.logo {
  height: 60px;
  margin-right: 1rem;
}
.custom-btn {
  background-color: $brand !important;
  min-width: 8rem !important;
  min-height: 40px;
}

.outline-none {
  outline: none !important
}

.z-999 {
  z-index: 999;
}

.t-0 { top: 0 }
.l-0 { left: 0 }
.r-0 { right: 0 }
.wrap-list-image {
  width: 100vh;
}
.wrap-image {
  position: relative;
  .ic-cancel {
    position: absolute;
    top: 0;
    right: 8px;
    color: red;
    cursor: pointer;
    border: 1px solid red;
    border-radius: 50%;
    opacity: 0;
    transition: opacity .15s ease-in-out;
    visibility: hidden;
    &:hover {
      color: #ffffff;
      background-color: red;
    }
  }
  &:hover {
    .ic-cancel {
      opacity: 1;
      visibility: visible;
    }
  }

}
.w-300 {
  width: 300px;
}
.w-400 {
  width: 400px;
}
.w-40 {
  width: 30%;
}
.w-25 {
  width: 25%;
}
.w-20 {
  width: 20%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-8 {
  width: 8%
}
.p-sm {
  padding: .5rem 5px !important;
}
.w-5 {
  width: 5%;
}
.limit-text {
  width: 100%;
  overflow: hidden;
  display: block;
}
.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
  width: 45px !important;
}
.fix-image {
  height: 15rem;
  width: auto;
  img {
    height: 100%;
    max-width: 100%;
  }
}
.MuiTableCell-sizeSmall {
  padding: .5rem !important;
}
.w-120 {
  width: 120px !important;
}
.custom-image {
  max-height: 10rem;
}
.hide-input {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
.MuiTabs-flexContainer {
  width: 600px;
}
